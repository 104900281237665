// extracted by mini-css-extract-plugin
export var articleList = "pizzas-article-list--SUGE3";
export var filters = "pizzas-filters--ka6g5";
export var filtersWrapper = "pizzas-filtersWrapper--vlI6i";
export var headingH3 = "pizzas-heading--h3--SMFVh";
export var imagesGrid = "pizzas-imagesGrid--JH3y6";
export var link = "pizzas-link--XTP1N";
export var linkText = "pizzas-link-text--Awsg8";
export var listCategory = "pizzas-list-category--o7ZG8";
export var listCircles = "pizzas-list-circles--hjBXw";
export var listInline = "pizzas-list-inline--uPjOb";
export var listPlain = "pizzas-list-plain--osA30";
export var listSmColumn = "pizzas-list-sm-column--AcdIR";
export var listTag = "pizzas-list-tag--GtIOS";
export var mainContent = "pizzas-mainContent--GJ2am";
export var pizzaDetailHeroImage = "pizzas-pizzaDetailHeroImage--FSd1l";
export var pizzaGrid = "pizzas-pizzaGrid--B+WsL";
export var pizzaImage = "pizzas-pizzaImage--67Ij0";
export var pizzaListingPage = "pizzas-pizzaListingPage--lK8RP";
export var pizzaTitle = "pizzas-pizza-title--W1M+V";
export var pizzaria = "pizzas-pizzaria--w3ErZ";
export var sectionHeadline = "pizzas-section-headline--qnT0J";
export var spacingVBLg = "pizzas-spacing-v-b-lg--J5Yxe";
export var spacingVBMd = "pizzas-spacing-v-b-md--DYTPF";
export var spacingVBSm = "pizzas-spacing-v-b-sm--nTJBd";
export var spacingVBZ = "pizzas-spacing-v-b-z--vQoky";
export var spacingVTLg = "pizzas-spacing-v-t-lg--aNdEC";
export var spacingVTMd = "pizzas-spacing-v-t-md--S5aI9";
export var spacingVTSm = "pizzas-spacing-v-t-sm--LwpZb";
export var spacingVTZ = "pizzas-spacing-v-t-z--pu2Rj";
export var textLink = "pizzas-textLink--shQel";
export var tmnt = "pizzas-tmnt--O4lbg";
export var uFlex = "pizzas-u-flex--DYAnP";
export var uLg1of4 = "pizzas-u-lg-1of4--6VNzp";
export var uLg3of4 = "pizzas-u-lg-3of4--q4mpo";
export var wrapper = "pizzas-wrapper--dDN0-";